.header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  height: 60px;
  background-color: #fafcff;
  border-bottom: 1px solid #06021614;
}
.header-wrapper .header-logo {
  width: 85px;
}
.header-wrapper ul {
  display: flex;
  align-items: center;
  list-style: none;
  margin-bottom: 0px;
}
.header-wrapper ul .footer_sider_bar {
  position: relative;
  margin-right: 25px;
  margin-bottom: 0px;
  padding: 15px 0px;
  color: #060216b8;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-decoration: none;
  cursor: pointer;
}
.header-wrapper ul .footer_sider_bar.active {
  font-weight: 600;
}
.header-wrapper ul .footer_sider_bar.active::after {
  content: "";
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  height: 4px;
  background-color: #285ff7;
}
.header-wrapper .user-settings-main {
  display: flex;
  align-items: center;
}
.header-wrapper .user-settings-main .docs-cum {
  position: relative;
  margin-right: 30px;
  margin-bottom: 0px;
  padding: 15px 0px;
  color: #060216b8;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
}
.header-wrapper .user-settings-main .logout-wrap {
  position: relative;
}
.header-wrapper .user-settings-main .logout-wrap .drop-list {
  position: absolute;
  right: 0px;
  top: 60px;
  width: 100px;
  padding: 0;
  background-color: #ffffff;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
}
.header-wrapper .user-settings-main .logout-wrap .drop-list .drop-item {
  width: 100%;
  color: rgba(6, 2, 22, 0.72);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  padding: 10px 15px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.header-wrapper .user-settings-main .logout-wrap .drop-list .drop-item:hover {
  background-color: rgba(0, 0, 0, 0.06);
}
.header-wrapper .user-settings-main .logout-wrap .logout-icon {
  width: 35px;
  height: 35px;
  cursor: pointer;
}
@media (max-width: 768px) {
  .mobile-header-active {
    position: relative;
    align-items: start;
    height: 135px;
    padding-top: 15px;
    border: 0;
    background-color: #eef0f6;
  }
  .mobile-header-active .nav-links {
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
  }
  .mobile-header-active .nav-links ul {
    justify-content: center;
  }
  .mobile-header-active .nav-links ul .footer_sider_bar {
    font-weight: 400;
    border: 1px solid #0000001f;
    border-radius: 8px;
    padding: 5px 15px;
  }
  .mobile-header-active .nav-links ul .footer_sider_bar.active {
    font-weight: 600;
    border-color: #285ff7;
    background-color: #e1e9ff;
  }
  .mobile-header-active .nav-links ul .footer_sider_bar.active::after {
    display: none;
  }
  .mobile-header-active .user-settings-main {
    display: none;
  }
}

/* ------------ privacy and terms and condition ----------- */
.privacy-n-termCondition {
  min-height: calc(100vh - 60px);
}
.privacy-n-termCondition .termCondition-container {
  padding-left: 20px;
  padding-right: 20px;
  background-color: #eef0f6;
}

.privacy-n-termCondition .termCondition-container {
  padding-top: 25px;
  padding-bottom: 35px;
}
.privacy-n-termCondition .aiml-card {
  border-radius: 16px;
  box-shadow: 0px 2px 22px 0px #06021612;
}
.privacy-n-termCondition .page-title {
  color: #060216;
  font-size: 32px;
  line-height: 30px;
  font-weight: 600;
}
.privacy-n-termCondition .content-title {
  color: #060216;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
}
.privacy-n-termCondition .content-subtitle {
  color: #060216;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 0px;
}
.privacy-n-termCondition .content-text,
.privacy-n-termCondition ul li {
  color: #060216;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}
.privacy-n-termCondition .content-text span {
  font-weight: 500;
}

.privacy-n-termCondition .aiml-primary-btn {
  display: flex;
  align-items: center;
}
.privacy-n-termCondition .aiml-primary-btn img {
  margin-right: 5px;
}
