@font-face {
  font-family: "General Sans";
  src: url("../fonts//GeneralSans/Fonts/TTF/GeneralSans-Variable.ttf")
      format("truetype"),
    url("../fonts/GeneralSans/Fonts/TTF/GeneralSans-VariableItalic.ttf")
      format("truetype"),
    url("../fonts/Inter/Inter-VariableFont_slnt\,wght.ttf") format("truetype");
}

.invalid-email-icon {
  position: absolute;
  top: 6px;
  right: 20px;
}
/* ------------------------------------- start ---------------------------------- */
.waitlist-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
  min-height: 100vh;
  margin: 20px 54px;
  margin-top: 0px;
}

.ai-ml-header {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 5px;
  padding-top: 20px;
}
.ai-ml-header .ai-mllogo {
  z-index: 2;
}
.ai-ml-header .first-interface {
  display: flex;
  align-items: center;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 0px;
}
.ai-ml-header .first-interface img {
  margin-right: 5px;
}
.content-wrapper {
  position: relative;
  padding-top: 100px;
  flex: 1;
  z-index: 9;
}
.top-heading-box .ai-ml-title {
  font-family: "General Sans", sans-serif;
  font-size: 100px;
  font-weight: 300;
  line-height: 98px;
  z-index: 2 !important;
  margin-bottom: 25px;
}
.top-heading-box .ai-ml-title .genAI {
  position: relative;
  display: inline-block;
  background-image: url("../assets/maskedImage.jpeg");
  background-size: cover;
  background-clip: text !important;
  -webkit-background-clip: text;
  color: transparent;
}
.ai-ml-subtitle {
  color: #ffffff;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 20px;
}
.input-wrapper {
  display: flex;
  align-items: center;
  padding: 20px 0;
  border-radius: 20px !important;
}
.input-container {
  position: relative;
}
.input-wrapper input {
  padding: 10px;
  margin-right: 10px;
  width: 423px;
  height: 40px;
  border-radius: 12px;
}
.input-wrapper .join-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  border-radius: 12px !important;
  border: 1px solid rgba(191, 87, 255, 0.8);
  background: linear-gradient(
      180deg,
      rgba(137, 6, 246, 0) 0%,
      rgba(137, 6, 246, 0.6) 141.25%
    ),
    #110c1d;
  padding: 10px 20px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  color: white;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  width: 180px;
  height: 40px;
  transition: all 0.3s ease;
}
.input-wrapper .join-button.arrow::after {
  border-right: 1.5px solid #ffffff;
  border-top: 1.5px solid #ffffff;
  content: "";
  display: inline-block;
  height: 6px;
  margin-left: 5px;
  transform: rotate(45deg);
  width: 6px;
}
.input-wrapper .join-button:hover {
  background: linear-gradient(
      176deg,
      rgba(137, 6, 246, 0) -283.72%,
      rgba(137, 6, 246, 0.6) 96.61%
    ),
    linear-gradient(
      180deg,
      rgba(137, 6, 246, 0) 0%,
      rgba(137, 6, 246, 0.6) 141.25%
    ),
    #110c1d;
  transition: all 0.5s ease-in-out;
}
@media (min-width: 769px) {
.ml-md-4 {
  margin-left: 1.5rem !important;
 }
}
.my-4{
  margin-top: 1.5rem !important;
  argin-bottom: 1.5rem !important;
}
.additional-info .waitlist-freeTokens {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.7);
}

.additional-info .waitlist-freeTokens .freeTokens {
  font-weight: 600;
}
.content-wrapper .efficient-info {
  width: 100%;
}
.efficient-info .info-card p {
  font-family: "General Sans", sans-serif;
  font-size: 60px;
  font-weight: 400;
  line-height: 68px;
  color: rgba(255, 255, 255, 1);
}

.efficient-info .info-card p .info-span {
  font-size: 40px;
  color: rgba(255, 255, 255, 0.52);
}

.efficient-info .info-paragraph {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 0;
}
.rectangle-strip {
  background: rgba(255, 255, 255, 0.1);
  position: relative;
  left: 0;
  z-index: 99;
  width: 100%;
  bottom: 1px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
  flex-wrap: wrap;
}
.rectangle-strip .rectangle-content {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 700;
  margin-top: 16px;
  color: rgba(255, 255, 255, 0.7);
  line-height: 20px;
  text-align: center;
}
.rectangle-strip .btn-box {
  display: flex;
  align-items: center;
}
.rectangle-strip .btn-box .footer-btn {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.7);
  line-height: 20px;
  padding: 0;
  border: 0;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
}
.rectangle-strip .btn-box .footer-btn:last-child {
  margin-left: 20px;
}
/* -------------------------------------- end ----------------------------------- */

.arrow-right {
  width: 16px;
  height: 16px;
  margin-top: -3px;
}

.error-message {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: rgba(239, 61, 87, 1);
  position: absolute;
  bottom: -20px;
  left: 0;
}

.form-control.invalid {
  border: 2px solid rgba(239, 61, 87, 1);
}

@media (min-width: 769px) and (max-width: 1200px) {
  .content-wrapper .top-heading-box .ai-ml-title {
    font-size: 60px;
  }
  .efficient-info .info-card p {
    font-size: 40px;
  }
  .efficient-info .info-card p .info-span {
    font-size: 20px;
  }
}
@media (max-width: 1400px) {
  .waitlist-container {
    height: 100%;
    min-height: 100vh;
  }
}
@media (max-width: 769px) {
  .waitlist-container {
    margin: 15px 10px;
    margin-top: 0px;
  }

  .ai-ml-header .ai-mllogo {
    width: 90px;
  }
  .top-right-title .first-interface {
    font-size: 12px;
    line-height: 20px;
  }
  .content-wrapper {
    padding-top: 100px;
    padding-bottom: 50px;
  }
  .content-wrapper .top-heading-box .ai-ml-title {
    font-size: 42px;
    line-height: 52px;
  }
  .content-wrapper .top-heading-box .ai-ml-subtitle {
    font-size: 16px;
    line-height: 24px;
  }
  .input-wrapper {
    flex-direction: column;
  }
  .input-container {
    width: 100%;
  }
  .input-wrapper input {
    width: 100%;
    margin-bottom: 15px;
  }
  .input-wrapper .join-button {
    /* width: 100%; */
  }
  .input-wrapper .join-button .join-waitist-text {
    text-align: center;
  }
  .additional-info .waitlist-freeTokens {
    font-size: 12px;
    line-height: 20px;
  }
  .efficient-info .info-card p {
    font-size: 52px;
    line-height: 64px;
  }
  .efficient-info .info-card p .info-span {
    font-size: 32px;
  }
  .efficient-info .info-paragraph {
    font-size: 12px;
    line-height: 20px;
  }
  .dNone {
    display: none;
  }
  .rectangle-strip {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 568px) {
  .rectangle-strip {
    height: auto;
    padding-bottom: 20px;
  }
}
