.app-container {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  /* height: 100vh;
  overflow: hidden; */
}

#background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.vertical-line {
  display: none;
}
.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.col-md-4,
.col-md-12 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
@media (max-width: 1400px) {
  .app-container {
    height: 100%;
    min-height: 100vh;
  }
}
@media (min-width: 769px) {
  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
/* --------- button theams --------- */

.aiml-primary-btn {
  height: 38px;
  padding: 5px 15px;
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  text-decoration: none;
  background-color: #285ff7;
  border: 1px solid #285ff7;
  border-radius: 12px;
  outline: 0;
  cursor: pointer;
}
.aiml-btn {
  height: 38px;
  padding: 5px 15px;
  color: rgba(6, 2, 22, 0.52);
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  background-color: #ffffff;
  border: 1px solid rgba(6, 2, 22, 0.22);
  border-radius: 12px;
  outline: 0;
  cursor: pointer;
}
/* -------------- button theme ----------------- */

.aiml-card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  transition: all 0.3s ease;
}
.aiml-card .ailm-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  margin-bottom: 0;
  border-bottom: 1px solid rgba(7, 3, 23, 0.078);
}
.aiml-card .aiml-card-body {
  height: 100%;
  flex: 1 1 auto;
  padding: 1rem 20px;
}
.aiml-card .card-footer {
  position: relative;
  padding: 1rem 1rem;
}
.d-flex {
  display: flex !important;
}
